import { api } from "../api/api";
import { mapEdiPurchaseOrderProperties } from "../constants/constants";

const errorHandler = (error) => {
  if (error?.code?.trim() == "ERR_NETWORK") {
    throw new Error(
      "It looks like we can't reach the server right now. Please try refreshing the page or check after sometime."
    );
  } else {
    throw new Error(error?.response?.data?.title);
  }
};

export const getEdiPurchaseOrders = async (params) => {
  try {
    const response = await api.get("/api/ediPurchaseOrder", { params });
    const processedResponse = mapEdiPurchaseOrderProperties(
      response.data?.result?.result || []
    );
    return processedResponse;
  } catch (error) {
    errorHandler(error);
  }
};

export const updateEdiPurchaseOrder = async (payload) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const response = await api.put(
      `/api/ediPurchaseOrder/submit`,
      payload,
      config
    );
    return response.data?.result?.result || [];
  } catch (error) {
    errorHandler(error);
  }
};

export const reProcessEdiPurchaseOrder = async (payload) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const response = await api.put(
      `/api/ediPurchaseOrder/reprocess`,
      payload,
      config
    );
    return response.data?.result?.result || [];
  } catch (error) {
    errorHandler(error);
  }
};

export const getEdiFileContent = async (params) => {
  try {
    const response = await api.get("/api/ediPurchaseOrder/edifile/content", {
      params
    });
    return response.data?.result?.result || [];
  } catch (error) {
    errorHandler(error);
  }
};

export const cancelEdiPurchaseOrders = async (payload) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const response = await api.put(
      `/api/ediPurchaseOrder/cancel`,
      payload,
      config
    );
    return response.data?.result?.result || [];
  } catch (error) {
    errorHandler(error);
  }
};

export const getReprocessEdiPurchaseOrders = async (params) => {
  try {
    const response = await api.get("/api/ediPurchaseOrder/group", {
      params
    });
    return response.data?.result?.result || [];
  } catch (error) {
    errorHandler(error);
  }
};

export const revertCancelledEdiPurchaseOrders = async (payload) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const response = await api.put(
      `/api/ediPurchaseOrder/revert`,
      payload,
      config
    );
    return response.data?.result?.result || [];
  } catch (error) {
    errorHandler(error);
  }
};

export const getDuplicateEdiPurchaseOrders = async () => {
  try {
    const response = await api.get("/api/ediPurchaseOrder/duplicate");
    return response.data?.result?.result || [];
  } catch (error) {
    errorHandler(error);
  }
};
